<template>
  <div
    v-if="loading"
    class="text-center"
    :class="{ 'pad-content': !padless }"
    :style="{ padding: p }"
  >
    <v-progress-circular width="1" indeterminate></v-progress-circular>
    <div v-if="loadingText" class="mt-2 loading-text">
      {{ loadingText }}
    </div>
  </div>
  <slot v-else-if="items && !items.length" name="empty"></slot>
  <slot v-else></slot>
</template>

<script lang="ts">
export default defineComponent({
  name: "ContentLoader",
  props: {
    loading: { type: Boolean, default: false },
    loadingText: { type: String, default: null },
    padless: { type: Boolean, default: false },
    padding: { type: String, default: "3rem" },
    items: { type: Array },
  },
  computed: {
    p() {
      return convertToUnit(this.padding);
    },
  },
});
</script>

<style lang="scss" scoped>
.loading-text {
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  font-weight: 300;
}
.pad-content {
  padding: var(--padding) 0;
}
</style>
